import VideoPlayer from "./VideoPlayer";

export default function Videos() {
  return <>
    <VideoPlayer videoId="hO8Ep7zevXg" />

    <div className='lg:flex lg:space-x-4 space-y-4 md:space-y-0 lg:justify-center'>
      <div className='flex space-x-4 justify-center'>
        <div className=''>
          <VideoPlayer sm videoId="eZvINXPv14s" />
        </div>
        <div className=''>
          <VideoPlayer sm videoId="KNEkH6YC860" />
        </div>
      </div>

      <div className='flex space-x-4 justify-center'>
        <div className=''>
          <VideoPlayer sm videoId="PsBKefCBNy4" />
        </div>
        <div className=''>
          <VideoPlayer sm videoId="8Z2oxxl1_hk" />
        </div>
      </div>
    </div>
  </>
}