export default function Banner() {
  return <div className="relative w-full bg-black"
    style={{
      height: '34rem'
    }}
  >
    <div
      className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/banner.gif)`,
        opacity: 0.7,
      }}
    />
    <div className="w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
      <h1 className='text-xl'>cinematic wedding films</h1>
      <h2 className=''>new york city</h2>
    </div>
  </div>
}