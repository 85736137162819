import Videos from './Videos';
import Banner from './Banner';
import Packages from './Packages';
import About from './About';

export default function Body() {
  return <div>
    <Banner />

    <div className='z-10 relative bottom-8 md:bottom-12 lg:bottom-16'>
      <img src='/paper-3.png' alt='paper' className='w-full rotate-180 relative top-2' />

      <div className='bg-paper2 relative'>
        <div className='w-11/12 md:w-4/5 mx-auto space-y-4 pt-4 pb-14'>
          <div className="image-container">
            <img src="/paper-2.png" alt="Paper" className="w-60 centered-image" />

            <div className="centered-text">
              <div className='font-bold'>recent films</div>
            </div>
          </div>

          <Videos />
        </div>
      </div>

    </div>

    <div className="relative bottom-20 w-full bg-black"
      style={{
        height: '32rem'
      }}
    >
      <div
        className="absolute top-0 left-0 w-full h-full bg-cover bg-top"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/aisle.gif)`,
          opacity: 0.7,
        }}
      />
      <div className="w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
        <div>Our services</div>
      </div>
    </div>

    <div className='relative bottom-8 md:bottom-0 text-center mx-auto w-11/12 md:w-6/12'>
      <div>
        We specialize in creating memorable, high quality wedding films.
        Our team is dedicated to capturing
        the authentic moments of your special day.
        Let us turn your love story into a lasting cinematic experience.
      </div>
    </div>

    <div className='relative bottom-8 md:bottom-0'>
      <div className='w-full md:w-4/5 mx-auto'>
        <Packages />
      </div>
    </div>

    <div className='font-bold text-3xl text-center mb-16'>Get in touch</div>

    <div className='relative'>
      <div className='w-11/12 md:my-0 my-8 md:w-full mx-auto'>
        <About />
      </div>
    </div>
  </div >
}