import React from 'react';
import YouTube from 'react-youtube';

export default function VideoPlayer({ videoId, sm }) {
  return (
    <div>
      <YouTube
        videoId={videoId}
        opts={{
          height: sm ? '250' : '500',
          width: '100%',
          playerVars: {
            autoplay: 0,
          },
        }}
      /></div>
  );
};

