export default function Packages() {
  return <div className="space-y-12">

    <div className='space-x-2 md:space-x-8 flex justify-between'>
      <div className='py-12 space-y-4 w-2/4 '>
        <div className="image-container">
          <img src="/paper-2.png" alt="Paper" className="w-60 centered-image" />

          <div className="centered-text">
            <div className='font-bold'>the highlight</div>
          </div>

        </div>

        <div className="w-full md:w-7/12 max-w-6xl mx-auto text-center space-y-2 p-4">
          <div className="pb-8">$4,500</div>
          <div>4-6 minute highlight film</div>
          <div>2 videographers</div>
          <div>8 hours of coverage</div>
          <div>1 week turnaround time</div>
        </div>

      </div>

      <div className='py-12 space-y-4 w-2/4 '>
        <div className="image-container">
          <img src="/paper-2.png" alt="Paper" className="w-60 centered-image" />

          <div className="centered-text">
            <div className='font-bold'>the documentary</div>
          </div>

        </div>
        <div className="w-full md:w-7/12 max-w-6xl mx-auto text-center space-y-2 p-4">
          <div className="pb-8">$6,000</div>
          <div>4-6 minute highlight film</div>
          <div>2 videographers</div>
          <div>8 hours of coverage</div>
          <div>1 week turnaround time</div>
          <div className='font-bold'>30 minute documentary film
            <br />- a longer, uncut version of the highlight film which will feature
            more moments from the ceremony, dances, and speeches
          </div>
        </div>

      </div>
    </div>
  </div>
}