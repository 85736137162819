export default function About() {
  return <div className="md:flex space-y-8 md:space-y-12">
    <div className="md:w-1/2 md:order-1">
      <img
        src="me.jpg"
        alt="Profile"
        className="w-4/5 mx-auto md:w-full rounded-full md:rounded-none "
      />
    </div>

    <div className="text-center md:w-1/2 md:order-2 md:flex md:flex-col md:justify-center md:items-center">
      <div className="image-container">
        <img src="/paper-2.png" alt="Paper" className="centered-image" />
        <div className="centered-text">
          Email Jed at jed@jedcal.com for inquiries and booking.
        </div>
      </div>
    </div>
  </div>
}